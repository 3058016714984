/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

.texture {
    background-image: url(./images/background-layer.svg);
    background-repeat: no-repeat;
    background-position: center;
}

input[type="range"] {
    -webkit-appearance: none; /* Remove default styling in WebKit browsers */
    width: 100%; /* Full width of the container */
    height: 10px; /* Height of the track */
    background: transparent; /* Make track background transparent */
    position: relative;
    transition: background 0.3s ease-in-out; /* Smooth transition for track background */
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    background: #ffffff60; /* Default track color */
    border-radius: 5px; /* Rounded corners */
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default styling in WebKit browsers */
    width: 50px; /* Thumb width */
    height: 30px; /* Thumb height */
    background: #0081BC; /* Thumb color */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 20px; /* Rounded thumb */
    position: relative;
    top:-10px;
    z-index: 1;
    transition: background 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition for thumb */
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 5px;
    background: #ddd; /* Default track color */
    border-radius: 5px; /* Rounded corners */
}

input[type="range"]::-moz-range-thumb {
    width: 50px; /* Thumb width */
    height: 30px; /* Thumb height */
    background: #0081BC; /* Thumb color */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 20px; /* Rounded thumb */
    transition: background 0.3s ease-in-out; /* Smooth transition for thumb */
}

input[type="range"]::-ms-track {
    width: 100%;
    height: 5px;
    background: transparent; /* Hide default track */
    border-color: transparent;
    color: transparent; /* Make track visible */
}

input[type="range"]::-ms-fill-lower {
    background: #ddd; /* Default track color */
    border-radius: 5px; /* Rounded corners */
}

input[type="range"]::-ms-fill-upper {
    background: #ddd; /* Default track color */
    border-radius: 5px; /* Rounded corners */
}

input[type="range"]::-ms-thumb {
    width: 50px; /* Thumb width */
    height: 30px; /* Thumb height */
    background: #0081BC; /* Thumb color */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 20px; /* Rounded thumb */
    transition: background 0.3s ease-in-out; /* Smooth transition for thumb */
}

/* Optional: Hover and Focus States */
input[type="range"]::-webkit-slider-thumb:hover,
input[type="range"]::-moz-range-thumb:hover,
input[type="range"]::-ms-thumb:hover {
    background: #005f8f; /* Darker color on hover */
}

input[type="range"]:focus::-webkit-slider-thumb,
input[type="range"]:focus::-moz-range-thumb,
input[type="range"]:focus::-ms-thumb {
    background: #005f8f; /* Darker color on focus */
}
